/* import __COLOCATED_TEMPLATE__ from './edit-sidebar-from-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { PinnedFolder, PinnedFolderType } from 'embercom/objects/inbox/pinned-folder';
import { type InboxFolder } from 'embercom/objects/inbox/inbox-folder';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import Admin from 'embercom/objects/inbox/inboxes/admin';
import Unassigned from 'embercom/objects/inbox/inboxes/unassigned';
import All from 'embercom/objects/inbox/inboxes/all';
import CreatedByYou from 'embercom/objects/inbox/inboxes/created-by-you';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';

const DEFAULT_PINNED_FOLDERS = [
  new PinnedFolder(PinnedFolderType.Team, null, 0),
  new PinnedFolder(PinnedFolderType.Admin, null, 1),
  new PinnedFolder(PinnedFolderType.View, null, 2),
];

const AI_CHATBOT_FOLDER = new PinnedFolder(PinnedFolderType.AiChatbot, null, 3);

interface Arguments {
  item: {
    pinnedFolders: PinnedFolder[];
    pinnedInboxes: Inbox[];
    toggleFolderPin: (folder: PinnedFolder) => void;
    toggleInboxPin: (inbox: Inbox) => void;
    customFolders: InboxFolder[];
    onCreateCustomFolder: () => void;
    showAiChatbotFolder: boolean;
  };
}

interface Signature {
  Args: Arguments;
}

export default class EditSidebarFromDropdown extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  // We don't want to update the list of folders every time the user pins or unpins a
  // folder.
  //
  // That's why the component keeps it's own state of what folders are pinned and what are
  // not and in what order.
  //
  // `folders` is an ordered list of all folders that are visible in the componnent (this
  // contains all pinned and unpinned folders).
  // `pinned` is the map of folder ids to whether they are pinned or not.
  // `customFolderNames` is a map of custom folder ids to their names.
  //
  // This state is updated when the user opens the popover.
  @tracked folders: PinnedFolder[] = [];
  @tracked pinned: { [key: string | number]: boolean } = {};
  @tracked customFolderNames: { [key: number]: string } = {};

  @action onOpen() {
    let systemFolders = this.args.item.showAiChatbotFolder
      ? [...DEFAULT_PINNED_FOLDERS, AI_CHATBOT_FOLDER]
      : DEFAULT_PINNED_FOLDERS;

    let customPinnedFolders = this.args.item.customFolders.map(
      (folder, index) =>
        new PinnedFolder(PinnedFolderType.Custom, folder.id, systemFolders.length + index),
    );

    let allFolders = systemFolders.concat(customPinnedFolders);

    let pinnedFolderIds = this.args.item.pinnedFolders.map((folder) => folder.id);

    let pinnedInboxIds = this.args.item.pinnedInboxes.map((inbox) => inbox.id);

    let allPinnedIds = [...pinnedFolderIds, ...pinnedInboxIds];

    let unpinnedFolders = allFolders.filter((folder) => !allPinnedIds.includes(folder.id));

    let folders = this.args.item.pinnedFolders.concat(unpinnedFolders);

    let pinned = allFolders.reduce(
      (acc, folder) => {
        acc[folder.id] = false;
        return acc;
      },
      {} as { [key: string | number]: boolean },
    );

    this.args.item.pinnedFolders.forEach((folder) => {
      pinned[folder.id] = true;
    });

    this.args.item.pinnedInboxes.forEach((inbox) => {
      pinned[inbox.id] = true;
    });

    let customFolderNames = this.args.item.customFolders.reduce(
      (acc, inboxFolder) => {
        acc[inboxFolder.id] = inboxFolder.name;
        return acc;
      },
      {} as { [key: number]: string },
    );

    this.folders = folders;
    this.pinned = pinned;
    this.customFolderNames = customFolderNames;
  }

  // Hard coded list of default pinned inboxes.
  get defaultPinnedInboxes() {
    return [
      new Admin(0, this.session.teammate, 0),
      new Mentions(0),
      new CreatedByYou(0),
      new All(0),
      new Unassigned(0),
    ];
  }

  get groupList() {
    let defaultFoldersGroup: { [key: string]: any } = {
      heading: this.intl.t(`inbox.inbox-list.default-folders`),
      items: this.folders
        .filter((folder) => folder.type !== PinnedFolderType.Custom)
        .map((folder) => {
          return {
            title: this.folderTitleForType(folder.type),
            pinned: this.pinned[folder.type],
            togglePin: () => this.toggleFolderPin(folder),
            component: 'inbox2/left-nav/edit-sidebar/item-pin',
            componentShouldReplaceItem: true,
          };
        }),
    };

    let groups = [defaultFoldersGroup];

    let inboxItemsGroup: { [key: string]: any } = {
      heading: this.intl.t(`inbox.inbox-list.inboxes-heading`),
      items: this.defaultPinnedInboxes.map((inboxItem) => {
        return {
          title: this.inboxTitleForType(inboxItem),
          pinned: this.pinned[inboxItem.id],
          togglePin: () => this.toggleInboxPin(inboxItem),
          component: 'inbox2/left-nav/edit-sidebar/item-pin',
          componentShouldReplaceItem: true,
        };
      }),
    };
    groups = [inboxItemsGroup, defaultFoldersGroup];

    let customFolderItems:
      | {
          title: string;
          pinned: boolean;
          togglePin: () => void;
          component: string;
          componentShouldReplaceItem: boolean;
        }[]
      | { text: string; component: string; componentShouldReplaceItem: true }[] = this.folders
      .filter((folder) => folder.type === PinnedFolderType.Custom)
      .map((folder) => {
        return {
          title: this.customFolderNames[folder.customFolderId as number],
          pinned: this.pinned[folder.id],
          togglePin: () => this.toggleFolderPin(folder),
          component: 'inbox2/left-nav/edit-sidebar/item-pin',
          componentShouldReplaceItem: true,
        };
      });

    customFolderItems =
      customFolderItems.length !== 0
        ? customFolderItems
        : [
            {
              text: this.intl.t(`inbox.inbox-list.nothing-yet`),
              component: 'inbox2/left-nav/edit-sidebar/item-text',
              componentShouldReplaceItem: true,
            },
          ];

    groups.push({
      heading: this.intl.t(`inbox.inbox-list.custom-folders`),
      items: customFolderItems,
    });

    groups.push({
      items: [
        {
          onClick: this.args.item.onCreateCustomFolder,
          component: 'inbox2/left-nav/edit-sidebar/item-create-custom-folder',
          componentShouldReplaceItem: true,
        },
      ],
    });

    return groups;
  }

  private toggleFolderPin(folder: PinnedFolder) {
    this.pinned = Object.assign({}, this.pinned, { [folder.id]: !this.pinned[folder.id] });
    this.args.item.toggleFolderPin(folder);
  }

  private toggleInboxPin(inbox: Inbox) {
    this.pinned = Object.assign({}, this.pinned, { [inbox.id]: !this.pinned[inbox.id] });
    this.args.item.toggleInboxPin(inbox);
  }

  private folderTitleForType(type: PinnedFolderType) {
    switch (type) {
      case PinnedFolderType.Team:
        return this.intl.t(
          this.session.workspace.isFeatureEnabled('team-product-guidance-helpdesk-setup')
            ? 'inbox.inbox-list.team-inboxes-heading'
            : 'inbox.inbox-list.teams-heading',
        );
      case PinnedFolderType.Admin:
        return this.intl.t(`inbox.inbox-list.teammates-heading`);
      case PinnedFolderType.View:
        return this.intl.t(`inbox.inbox-list.views-heading`);
      case PinnedFolderType.AiChatbot:
        return this.intl.t('inbox.inbox-list.ai-chatbot-heading');
      default:
        throw new Error(`Missing inbox.inbox-list.folders.${type} translation key`);
    }
  }

  private inboxTitleForType(inbox: Inbox) {
    if (inbox.translationKey) {
      return this.intl.t(inbox.translationKey);
    } else {
      return this.intl.t(`inbox.inbox-list.your-inbox`);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::EditSidebarFromDropdown': typeof EditSidebarFromDropdown;
    'inbox2/left-nav/edit-sidebar-from-dropdown': typeof EditSidebarFromDropdown;
  }
}
