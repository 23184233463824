/* import __COLOCATED_TEMPLATE__ from './phone-call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';

interface Args {}

export default class PhoneCallModal extends Component<Args> {
  @service declare twilioService: TwilioService;
  @service declare session: Session;
  @service declare intl: IntlService;

  @tracked isMuted = false;

  @action hangUp() {
    if (this.twilioService.activeCall && this.twilioService.incomingCall) {
      this.twilioService.activeCall.hangedUpByAdmin = true;
      return this.twilioService.teammateHangUp();
    }
    return this.twilioService.activeCall?.hangUp();
  }

  @action pressDigit(digit: string) {
    this.twilioService.activeCall?.pressDigit(digit);
  }

  @action toggleOnHold() {
    taskFor(this.twilioService.toggleOnHold).perform();
  }

  @action mute() {
    if (this.twilioService.activeCall) {
      this.isMuted = this.twilioService.activeCall.mute();
    }
  }

  @action
  toggleRecording() {
    this.twilioService.toggleRecording();
  }

  get state() {
    return this.twilioService.callState || 'connecting';
  }

  get userSummary() {
    return this.twilioService.userSummary;
  }

  get isTimerVisible() {
    return this.state === 'accept';
  }

  get renderOnHold() {
    return this.state === 'accept';
  }

  get renderTransfer() {
    return (
      this.state === 'accept' &&
      (this.twilioService.incomingCall !== null ||
        this.session.workspace.isFeatureEnabled('team-phone-outbound-transfers'))
    );
  }

  get renderAddParticipant() {
    return (
      this.state === 'accept' && this.session.workspace.isFeatureEnabled('phone-conference-calls')
    );
  }

  get startTime() {
    return this.twilioService.activeCall?.startTime;
  }

  get isOnHold() {
    return this.twilioService.isOnHold;
  }

  get disableHold() {
    return this.twilioService.disableHold || taskFor(this.twilioService.toggleOnHold).isRunning;
  }

  get isTransferTaskRunning() {
    return (
      taskFor(this.twilioService.transferToTeam).isRunning ||
      taskFor(this.twilioService.transferToAdmin).isRunning ||
      taskFor(this.twilioService.transferToExternalNumber).isRunning
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneCallModal': typeof PhoneCallModal;
    'inbox2/phone-call-modal': typeof PhoneCallModal;
  }
}
