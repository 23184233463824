/* import __COLOCATED_TEMPLATE__ from './multi-state-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type CommandKService from 'embercom/services/command-k';
import { type UpdateTicketStateCommandKContext } from 'embercom/objects/inbox/command-k/update-ticket-state';
import {
  buildUpdateTicketStateCommandKContext,
  localisedCustomStateAdminLabel,
} from 'embercom/lib/inbox2/ticket-custom-states';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';

interface Args {
  conversation: Conversation;
  changeTicketState: (
    conversation: Conversation,
    ticketState: TicketCustomState,
    trackingSection?: string,
    adminLabel?: string,
  ) => unknown;
  openConversation: (conversation: Conversation) => unknown;
  skipResolvedCheck?: boolean;
  iconOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class MultiStateButton extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare commandK: CommandKService;
  @service declare ticketStateService: TicketStateService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare session: Session;

  @tracked showUpdateLinkedReportsModal = false;
  @tracked selectedState?: ConversationState | TicketCustomState['id'];

  get currentTicketState() {
    if (!this.args.conversation) {
      return undefined;
    }

    if (this.currentTicketCustomState) {
      return this.currentTicketCustomState.systemState;
    }

    return this.args.conversation.ticketState;
  }

  get currentTicketCustomState(): TicketCustomState {
    return this.ticketStateService.getTicketCustomStateById(
      this.args.conversation.ticketCustomStateId,
    );
  }

  get displayIcon(): string {
    if (this.args.conversation.isTicket) {
      return 'ticket';
    } else {
      return 'close-conversation';
    }
  }

  get displayText(): string {
    if (this.args.conversation.isTicket) {
      if (this.currentTicketCustomState) {
        return localisedCustomStateAdminLabel(this.currentTicketCustomState.adminLabel, this.intl);
      }
      let state = this.currentTicketState || TicketSystemState.Submitted;
      return this.intl.t(`inbox.ticket-state.${state}`);
    } else {
      return this.intl.t('inbox.multi-state-button.actions.close');
    }
  }

  get linkedCustomerTicketIds() {
    return this.args.conversation.linkedCustomerTicketIds || [];
  }

  get linkedCustomerReportIds() {
    return this.args.conversation.linkedCustomerReportIds;
  }

  get ticketStateButtonCssClass() {
    if (this.currentTicketState === undefined) {
      return '';
    }

    return {
      [TicketSystemState.Submitted]: 'ticket-state__submitted',
      [TicketSystemState.InProgress]: 'ticket-state__in-progress',
      [TicketSystemState.WaitingOnCustomer]: 'ticket-state__waiting-on-customer',
      [TicketSystemState.Resolved]: 'ticket-state__resolved',
    }[this.currentTicketState];
  }

  get updateTicketStateCommandKContext(): UpdateTicketStateCommandKContext {
    return buildUpdateTicketStateCommandKContext({
      customStates: this.getCustomStatesForConversationTicketType(),
      selectedCustomStateId: this.currentTicketCustomState.id,
      allowClosedState: true,
      intl: this.intl,
      conversation: this.args.conversation,
      showSubmittedStates: this.session.workspace.isFeatureEnabled(
        'tickets-multiple-submitted-states',
      ),
    });
  }

  getCustomStatesForConversationTicketType() {
    if (!this.args.conversation?.ticketType) {
      return this.ticketStateService.customStates;
    }

    return this.ticketStateService.getLiveCustomStatesForTicketType(
      this.args.conversation.ticketType,
    );
  }

  get ticketStateButtonTooltip() {
    if (this.args.iconOnly) {
      return this.displayText;
    }

    return this.intl.t('inbox.ticket-state.change-ticket-state-button-tooltip');
  }

  get canBeResolved() {
    return this.args.conversation.isTicket && !this.args.conversation.isClosed;
  }

  @action async changeTicketState(selectedStateId: TicketCustomState['id']) {
    if (!this.args.changeTicketState) {
      return;
    }

    let ticketCustomState = this.ticketStateService.getTicketCustomStateById(selectedStateId);
    let previousSystemState = this.args.conversation.ticketState;
    let previousState = this.args.conversation.ticketCustomStateId;
    this.args.conversation.ticketState = ticketCustomState.systemState;
    this.args.conversation.ticketCustomStateId = ticketCustomState.id;

    let response = await this.args.changeTicketState(
      this.args.conversation,
      ticketCustomState,
      undefined,
      ticketCustomState.adminLabel,
    );
    if (!response) {
      this.args.conversation.ticketState = previousSystemState;
      this.args.conversation.ticketCustomStateId = previousState;
    }
  }

  @action async onUpdateTrackerWithoutUpdatingLinkedReports() {
    this.showUpdateLinkedReportsModal = false;

    this.changeTicketState(this.selectedState as TicketCustomState['id']);
  }

  @action async onUpdateTrackerAndLinkedReportStates() {
    this.showUpdateLinkedReportsModal = false;

    this.changeTicketState(this.selectedState as TicketCustomState['id']);
    await this.inboxApi.bulkChangeTicketState(
      this.linkedCustomerReportIds,
      this.selectedState as TicketCustomState['id'],
    );
  }

  @action async onReopen() {
    await this.args.openConversation(this.args.conversation);
  }

  @action async onMaybeClose() {
    this.conversationResolveAndCloseService.startResolveAndCloseProcess(this.args.conversation);
  }

  shouldShowUpdateLinkedReportsModal(state: ConversationState | TicketSystemState | number) {
    if (!this.args.conversation.isTrackerTicket || state === ConversationState.Closed) {
      return false;
    }

    return this.linkedCustomerReportIds.length > 0;
  }

  @action async handleStateChange(state: ConversationState | TicketCustomState['id']) {
    if (state === ConversationState.Closed) {
      return await this.onMaybeClose();
    }

    if (this.shouldShowUpdateLinkedReportsModal(state)) {
      this.selectedState = state;
      this.showUpdateLinkedReportsModal = true;
    } else {
      this.changeTicketState(state as TicketCustomState['id']);
    }
  }

  @action async openUpdateTicketStatePane() {
    this.commandK.registerAndShow({
      actionID: 'update-ticket-state',
      context: this.updateTicketStateCommandKContext,
      onSelect: this.handleStateChange,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::MultiStateButton': typeof MultiStateButton;
    'inbox2/multi-state-button': typeof MultiStateButton;
  }
}
