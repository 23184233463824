/* import __COLOCATED_TEMPLATE__ from './product-ia-optin-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    displayModal: boolean;
    onModalClose: () => void;
    onOptIn: () => void;
  };
}

const ProductIaOptinModal = templateOnlyComponent<Signature>();
export default ProductIaOptinModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::ProductIaOptinModal': typeof ProductIaOptinModal;
    'inbox2/user-menu/product-ia-optin-modal': typeof ProductIaOptinModal;
  }
}
